.wrapper {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 44.8px;

  .searchInput {
    border-radius: 25px;
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15) inset;
    width: 100%;
    color: #000000;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding: 13px 40px 13px 20px;
    transition: 0.15s ease-in-out;

    &::placeholder {
      color: #000000;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 11px 14px 11px 11px;
    position: absolute;
    right: 0px;
    top: 0;
    border-radius: 0 25px 25px 0 ;

    svg {
      transition: 0.15s ease-in-out;
    }

    &:hover {

      svg {
        stroke : #000;
        transform : scale(1.1);
      }
    }
  }

  &.focus {

    .searchInput {
      border-color: #be1212;
    }

    .searchButton {

      svg {
        stroke: #be1212;
      }
    }
  }

  &.active {

    .searchInput {
      border-color: #be1212;
      background-color: #BE1212;
      color: #ffffff;
    }

    .searchButton {

      svg {
        stroke: #ffffff;
      }
    }
  }
}