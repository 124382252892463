.wrapper {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 44.8px;
}
.wrapper .searchInput {
  border-radius: 25px;
  border: 1px solid #ECECEC;
  background: #FFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15) inset;
  width: 100%;
  color: #000000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 13px 40px 13px 20px;
  transition: 0.15s ease-in-out;
}
.wrapper .searchInput::-moz-placeholder {
  color: #000000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.wrapper .searchInput::placeholder {
  color: #000000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.wrapper .searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  padding: 11px 14px 11px 11px;
  position: absolute;
  right: 0px;
  top: 0;
  border-radius: 0 25px 25px 0;
}
.wrapper .searchButton svg {
  transition: 0.15s ease-in-out;
}
.wrapper .searchButton:hover svg {
  stroke: #000;
  transform: scale(1.1);
}
.wrapper.focus .searchInput {
  border-color: #be1212;
}
.wrapper.focus .searchButton svg {
  stroke: #be1212;
}
.wrapper.active .searchInput {
  border-color: #be1212;
  background-color: #BE1212;
  color: #ffffff;
}
.wrapper.active .searchButton svg {
  stroke: #ffffff;
}/*# sourceMappingURL=searchInput.module.css.map */