.wrapper {
  display: flex;
}
.wrapper .shareButton {
  border: unset;
  cursor: pointer;
  background-color: unset;
  padding: unset;
  display: flex;
}
.wrapper .shareButton svg {
  width: 30px;
  height: 30px;
}
.wrapper .shareButton rect {
  transition: 0.3s all;
}
.wrapper .shareButton:hover rect {
  fill: #000000;
}/*# sourceMappingURL=socialShare.module.css.map */