.print {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.print table {
  display: flex;
  flex-flow: column;
}
.print table tr {
  display: flex;
  flex-flow: column;
}
.print table td {
  padding: unset;
  display: flex;
  flex-flow: column;
}
.print .pageHeader,
.print .pageHeaderSpace {
  height: 110px;
  display: none;
}
.print .pageFooter,
.print .pageFooterSpace {
  height: 100px;
  display: none;
}
.print .pageFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #dddddd;
  display: none;
  height: 80px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.print .pageFooter .images {
  display: flex;
  align-items: center;
}
.print .pageFooter .images .firstImage {
  width: 150px;
}
.print .pageFooter .images .middleImage {
  width: 45px;
  height: 46px;
  margin: 0 32px;
}
.print .pageFooter .images .lastImage {
  width: 63px;
}
.print .pageFooter .images .middleImageEN {
  background-image: url("../../assets/images/EN/nifcLogoEN.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 45px;
  height: 46px;
  margin: 0 32px;
}
.print .pageHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  display: none;
  height: 80px;
  padding-top: 10px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
}
.print .pageHeader .image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 37px;
}
.print .pageHeader .link {
  font-size: 10px;
  color: #BE1212;
}

@page {
  size: A4;
  margin: 0 11mm;
}
@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  tbody {
    display: table-row-group;
  }
  .print {
    display: block;
  }
  .print table {
    display: table;
    flex-flow: unset;
  }
  .print table tr {
    display: table-row;
    flex-flow: unset;
  }
  .print table td {
    display: table-cell;
    flex-flow: unset;
  }
  .print .pageHeader,
  .print .pageHeaderSpace {
    display: flex;
  }
  .print .pageFooter,
  .print .pageFooterSpace {
    display: flex;
  }
  .print .pageFooter {
    display: flex;
  }
  .print .pageHeader {
    display: flex;
  }
}/*# sourceMappingURL=pdfPage.module.css.map */