.container {
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 44px;
  width: 100%;
}
.container.light .openSort .label .value {
  font-weight: 400;
}
.container.light .openSort.active .label .value {
  font-weight: 700;
}
.container .openSort {
  position: relative;
  height: 44px;
  border-radius: 25px;
  border: 1px solid #ECECEC;
  background: #FFFFFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15) inset;
  padding: 0 15px;
  transition: 0.25s ease-in-out;
}
.container .openSort .label {
  display: flex;
  align-items: center;
  width: 100%;
}
.container .openSort .label .pre {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 4px;
  transition: 0.25s ease-in-out;
}
.container .openSort .label .value {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-right: auto;
  transition: 0.25s ease-in-out;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.container .openSort .label .arrow {
  display: flex;
  width: 14px;
  height: 8px;
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
  margin-left: 10px;
  margin-right: 5px;
}
.container .openSort.closed .label .arrow {
  transform: rotate(0deg);
}
.container .openSort.active {
  background-color: #BE1212;
}
.container .openSort.active .label .pre {
  color: #ffffff;
}
.container .openSort.active .label .value {
  color: #ffffff;
}
.container .openSort.active .label .arrow svg path {
  stroke: #ffffff;
}
.container .sortOptionsContainer {
  position: absolute;
  left: 0;
  top: 52px;
  z-index: 2;
  width: 100%;
  box-shadow: 20px 20px 40px 0 rgba(0, 0, 0, 0.15);
  border-radius: 22px;
  overflow: hidden;
}
.container .sortOptionsContainer .sortOptionsWrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  background: #FFF;
  border-radius: 22px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15) inset;
  max-height: 241px;
  overflow: auto;
  scrollbar-color: #be1212 #D9D9D9;
  scrollbar-width: auto;
}
.container .sortOptionsContainer .sortOptionsWrapper::-webkit-scrollbar {
  width: 21px;
  background: #D9D9D9;
}
.container .sortOptionsContainer .sortOptionsWrapper::-webkit-scrollbar-thumb {
  background-color: #be1212;
  border-radius: 30px;
  max-height: 30px;
}
.container .sortOptionsContainer .sortOptionsWrapper .option {
  border: unset;
  color: #000;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  transition: all 0.25s ease-in-out;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 14px 13px;
  border-radius: 25px;
}
.container .sortOptionsContainer .sortOptionsWrapper .option .label {
  display: flex;
  align-items: center;
  width: 100%;
}
.container .sortOptionsContainer .sortOptionsWrapper .option .label .pre {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 4px;
  transition: 0.25s ease-in-out;
}
.container .sortOptionsContainer .sortOptionsWrapper .option .label .value {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-right: auto;
  transition: 0.25s ease-in-out;
}
.container .sortOptionsContainer .sortOptionsWrapper .option:hover {
  background: #be1212;
  color: #FFFFFF;
}
.container .sortOptionsContainer .sortOptionsWrapper .option:hover .pre {
  color: #FFFFFF;
}
.container .sortOptionsContainer .sortOptionsWrapper .option:hover .value {
  color: #FFFFFF;
}/*# sourceMappingURL=selectInput.module.css.map */