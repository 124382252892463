.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #ffffff;

  .container {
    display: flex;
    flex-flow: column;
    max-width: 1320px;
    padding: 0 40px;
    margin: 80px auto 100px;
    width: 100%;

    div {
      color: #1D1D1B;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.62;
    }

    p {
      color: #1D1D1B;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.62;
    }

    a {
      color: #a81c1c !important;
      font-family: inherit !important;
      font-size: inherit !important;
      line-height: inherit !important;
      text-decoration: underline !important;
      word-break: break-word;
      font-weight: 700;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {

    .container {
      padding: 0 30px;
    }
  }
}

@media (max-width: 500px) {
  .wrapper {

    .container {
      padding: 0 20px;

      div {
        font-size: 14px;
        line-height: 1.2;
      }

      p {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}