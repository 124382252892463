.print {
  display: flex;
  flex-flow: column;
  width: 100%;

  table {
    display: flex;
    flex-flow: column;

    tr {
      display: flex;
      flex-flow: column;
    }

    td {
      padding: unset;
      display: flex;
      flex-flow: column;
    }
  }

  .pageHeader,
  .pageHeaderSpace {
    height: 110px;
    display: none;
  }

  .pageFooter,
  .pageFooterSpace {
    height: 100px;
    display: none;
  }

  .pageFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #dddddd;
    display: none;
    height: 80px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;

    .images {
      display: flex;
      align-items: center;

      .firstImage {
        width: 150px;
      }

      .middleImage {
        width: 45px;
        height: 46px;
        margin: 0 32px;
      }

      .lastImage {
        width: 63px;
      }

      .middleImageEN {
        background-image: url("../../assets/images/EN/nifcLogoEN.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 45px;
        height: 46px;
        margin: 0 32px;
      }

    }
  }

  .pageHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    display: none;
    height: 80px;
    padding-top: 10px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 102px;
      height: 37px;
    }

    .link {
      font-size: 10px;
      color: #BE1212;
    }
  }
}

@page {
  size: A4;
  margin: 0 11mm;
}

@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  tbody {
    display: table-row-group;
  }

  .print {
    display: block;

    table {
      display: table;
      flex-flow: unset;

      tr {
        display: table-row;
        flex-flow: unset;
      }

      td {
        display: table-cell;
        flex-flow: unset;
      }
    }

    .pageHeader,
    .pageHeaderSpace {
      display: flex;
    }

    .pageFooter,
    .pageFooterSpace {
      display: flex;
    }

    .pageFooter {
      display: flex;
    }

    .pageHeader {
      display: flex;
    }
  }
}