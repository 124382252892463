.wrapper {
  display: flex;
  flex-flow: column;

  .container {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 1036px;
    margin: 42px auto 160px;
    padding: 0 108px;
    position: relative;

    .socialWrapper {
      position: absolute;
      top: 0;
      right: 0;

      div {
        display: block;
      }
    }

    .button {
      position: absolute;
      padding-right: 10px;
      display: flex;
      align-items: center;
      left: 0;

      .arrow {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: 2px solid #be1212;
        border-radius: 50%;
        transition: 0.25s ease-in-out;
        overflow: hidden;
        padding: unset;
        display: flex;
        align-items: center;
        margin-right: 7px;

        .arrowWrapper {
          display: flex;
          flex-shrink: 0;
          transition: 0.3s ease-in-out;

          svg {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            margin-right: 5px;
            margin-left: 4px;
          }
        }
      }

      span {
        color: #1D1D1B;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        transition: 0.3s ease-in-out;
      }

      &:hover {

        .arrow {

          .arrowWrapper {
            transform: translateX(-27px);
          }
        }

        span {
          color: #BE1212;
        }
      }
    }

    .image {
      width: 100%;
      height: auto;
    }

    .date {
      color: #9F9F9F;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin: 29px 0 25px;
    }

    .title {
      color: #a81c1c;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      margin: 0;
    }

    .authors {
      color: #1D1D1B;
    }

    .textWrapper {
      color: #1D1D1B;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625;
      margin-top: 30px;
      text-align: start;
      margin-bottom: 86px;

      div {
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        text-align: inherit !important;
      }

      p {
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        text-align: inherit !important;
      }

      span {
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        text-align: inherit !important;
      }

      strong {
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
      }

      a {
        color: #a81c1c !important;
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
        text-decoration: underline !important;
        word-break: break-word;
        font-weight: 700;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      iframe {
        width: 100%;
        max-width: 800px;
        aspect-ratio: 16/9;
        height: auto;
        border: unset;
      }

      blockquote {
        color: #BE1212;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        margin: 60px 85px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          right: 101%;
          top: 0;
          width: 35px;
          height: 35px;
          background: url(../../assets/images/general/quote.svg) no-repeat;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .sliderTitle {
    color: #1D1D1B;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 35px 0;
    max-width: calc((100% - 1240px) /2 + 1240px + 20px);
    width: 100%;
  }
}

.carousel {
  width: 70%;
  max-width: calc((100% - 1240px) /2 + 1240px + 20px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  .carouselRow {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .track {
    width: 100%;
    padding-lesliderft: 20px !important;

    .slide {
      div:has(> span) {
        margin: 5px 0;
      }
    }

  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 68px;
    width: 100%;
    margin-left: auto;

  }

  .arrow {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid #be1212;
    border-radius: 50%;
    transition: 0.25s ease-in-out;
    overflow: hidden;
    padding: unset;

    &:disabled {
      opacity: 0;
      visibility: hidden;
    }
  }

  .arrowWrapper {
    display: flex;
    flex-shrink: 0;
    transition: 0.3s ease-in-out;

    svg {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 5px;
      margin-left: 4px;
    }

    &:hover {
      transform: translateX(-27px);
    }
  }

  .rightArrow {

    svg {
      transform: rotate(-180deg);
    }

    .arrowWrapper {
      transform: translateX(-27px);

      &:hover {
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 1500px) {
  .carousel {
    width: 80%;
  }
}

@media (max-width: 1000px) {

  .wrapper {

    .container {
      padding: 0 30px;

      .socialWrapper {
        top: unset;
        right: unset;
        bottom: -60px;
        left: 20px;

        div {
          display: flex;
        }
      }

      .button {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 800px) {

  .wrapper {

    .container {

      .title {
        font-size: 32px;
      }

      .textWrapper {
        font-size: 15px;

        blockquote {
          font-size: 20px;
          line-height: 26px;
          margin: 40px 60px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .wrapper {

    .container {

      .textWrapper {

        blockquote {
          margin: 30px 40px;
        }
      }
    }
  }
}

@media (max-width: 680px) {

  .wrapper {

    .container {

      .title {
        font-size: 30px;
      }

      .textWrapper {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 500px) {

  .wrapper {

    .sliderTitle {
      font-size: 20px;
    }

    .container {
      padding: 0 20px;
      margin-top: 30px;

      .button {
        margin-bottom: 20px;
      }

      .title {

        font-size: 25px;
      }

      .textWrapper {

        blockquote {
          font-size: 20px;
          line-height: 26px;
          margin: 20px 30px 20px 40px;
        }
      }
    }
  }
}

@media (max-width: 450px) {

  .wrapper {

    .sliderTitle {
      font-size: 18px;
    }

    .container {

      .title {
        font-size: 20px;
      }

      .textWrapper {

        blockquote {
          font-size: 18px;
          line-height: 24px;
          margin: 20px 30px 20px 40px;
        }
      }
    }
  }
}


@media (max-width: 400px) {

  .wrapper {

    .container {

      .title {
        font-size: 16px;
      }

      .textWrapper {

        blockquote {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

@media print {
  .wrapper {

    .shareButton {
      display: none;
    }

    .container {
      padding: unset;
      max-width: unset;

      .socialWrapper {
        display: none;
      }

      .button {
        display: none;
      }

      .image {
        max-height: 850px;
        width: auto;
      }

      .textWrapper {
        display: grid;

        p {
          margin-top: unset;
        }

        img {
          max-height: 850px;
          width: auto;
        }
      }
    }

    .sliderTitle {
      display: none;
    }

    .carousel {
      display: none;
    }
  }
}