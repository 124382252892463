.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;

  .top {
    display: flex;
    width: 100%;
    background-color: #EFEFEF;
    padding: 25px 20px 32px;

    .container {
      display: flex;
      max-width: 1240px;
      width: 100%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.25s ease-in-out;

        svg {
          width: 185px;
          height: 68px;
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      .middle {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        max-width: 580px;
        margin: 0 40px;
        grid-column-gap: 38px;
        grid-row-gap: 16px;

        .link {
          color: #1D1D1B;
          font-family: "Plus Jakarta Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          position: relative;
          justify-self: start;
          white-space: nowrap;

          &:after {
            background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)), linear-gradient(90deg, #1D1D1B, #1D1D1B);
            background-position: 100% 100%, 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px, 0 1px;
            bottom: 0;
            content: "";
            left: 0;
            padding-bottom: 4px;
            position: absolute;
            transition: background-size .3s;
            width: 100%;
          }

          &:hover {

            &:after {
              background-size: 0 1px, 100% 1px
            }
          }
        }
      }

      .right {
        display: flex;
        flex-flow: column;

        .title {
          color: #1D1D1B;
          font-family: "Plus Jakarta Sans", sans-serif;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          margin: 0 0 8px;
        }

        .images {
          display: flex;
          align-items: center;

          .firstImage {
            background-image: url("../../assets/images/PL/MKiDN_PL.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 150px;
            height: 32px;
          }

          .middleImage {
            width: 45px;
            height: 46px;
            margin: 0 32px;
          }

          .lastImage {
            background-image: url("../../assets/images/PL/DMP_PL.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 63px;
            height: 46px;
          }

          .firstImageEn {
            background-image: url("../../assets/images/EN/logoMkidnEN.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 130px;
            height: 52px;
          }

          .middleImageEn {
            width: 45px;
            height: 46px;
            margin: 0 25px;
            background-image: url("../../assets/images/EN/nifcLogoEN.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }

          .lastImageEn {
            background-image: url("../../assets/images/PL/DMP_PL.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 63px;
            height: 46px;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    background-color: #1D1D1B;
    padding: 10px 70px 10px 20px;
    min-height: 38px;
    align-items: center;

    .container {
      display: flex;
      max-width: 1240px;
      width: 100%;
      margin: 0 auto;
      justify-content: space-between;
      position: relative;

      .arrowGoToTop {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: unset;
        position: absolute;
        right: -62px;
        top: 0;
        background-color: #BE1212;
        border: 2px solid #BE1212;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        transform: translateY(calc(-10px - 50%));
        z-index: 1;
        transition: background-color 0.25s ease-in-out;

        svg {
          width: 22px;
          height: 12px;
          transform: rotate(180deg);
          margin-bottom: 4px;

          path {
            stroke: #ffffff;
            transition: 0.25s ease-in-out;
          }
        }

        &:hover {
          background-color: #ffffff;

          svg {
            path {
              stroke: #BE1212;
            }
          }
        }
      }

      .rights {
        color: #FFF;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .link {
        color: #FFF;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;

        &:after {
          background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0)), linear-gradient(90deg, #fff, #fff);
          background-position: 100% 100%, 0 100%;
          background-repeat: no-repeat;
          background-size: 100% 1px, 0 1px;
          bottom: 0;
          content: "";
          left: 0;
          padding-bottom: 4px;
          position: absolute;
          transition: background-size .3s;
          width: 100%;
        }

        &:hover {

          &:after {
            background-size: 0 1px, 100% 1px
          }
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .wrapper {

    .top {

      .container {

        .middle {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .wrapper {

    .top {

      .container {

        .middle {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {

    .top {

      .container {
        flex-wrap: wrap;

        .middle {
          order: 2;
          max-width: unset;
          width: 100%;
          grid-template-columns: repeat(6, 1fr);
          margin: 20px 0 0;
        }

        .right {
          order: 1;
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .wrapper {

    .top {

      .container {

        .middle {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .wrapper {

    .top {

      .container {

        .middle {
          order: 1;
          grid-template-columns: repeat(4, 1fr);
        }

        .right {
          order: 2;
          margin-top: 35px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .wrapper {

    .top {
      padding: 25px 20px 40px;

      .container {

        .middle {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .bottom {
      padding: 20px 20px;

      .container {
        flex-flow: column;
        align-items: center;

        .arrowGoToTop {
          right: -5px;
          top: -20px;
        }

        .link {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .wrapper {

    .top {

      .container {

        .middle {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .wrapper {

    .top {

      .container {

        .right {

          .images {

            .firstImage {
              width: 135px;
              height: 28px;
            }

            .middleImage {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}

@media print {
  .wrapper {
    display: none;
  }
}