.button {
  padding: unset;

  .buttonContent {
    display: flex;

    .mainImage {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 46px;
        height: 53px;

        path {
          transition: 0.3s ease-in-out;
        }
      }
    }

    .texts {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      margin-left: 15px;
      margin-top: 4px;

      .topText {
        color: #1D1D1B;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        transition: 0.3s ease-in-out;
      }

      .bottomText {
        color: #BE1212;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        transition: 0.3s ease-in-out;
        margin-top: 3px;
      }
    }
  }

  &:hover {

    .buttonContent {

      .mainImage {

        svg {
          path {
            fill: #BE1212;
          }
        }
      }

      .texts {

        .topText {
          color: #BE1212;
        }

        .bottomText {
          color: #1D1D1B;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .button {

    .buttonContent {

      .texts {

        .topText {
          font-size: 16px;
        }
      }
    }
  }
}

@media print {
  .button {
    display: none;
  }
}