.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.wrapper .topWrapper {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  height: 487px;
  overflow: hidden;
  position: relative;
}
.wrapper .topWrapper .absoluteWrapper {
  display: flex;
  height: 100%;
  position: absolute;
  width: 1440px;
  margin: 0 auto;
  flex-shrink: 0;
  top: 0;
  left: calc((100% - 1440px) / 2);
}
.wrapper .topWrapper .absoluteWrapper .image {
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
  transition: 0.4s ease-in-out;
}
.wrapper .topWrapper .absoluteWrapper .image.composer1 {
  width: 139px;
  height: 167px;
  background-image: url("../../assets/images/Composers/composer1.jpg");
  left: 0.52%;
  top: 150px;
  filter: blur(2px);
  opacity: 0.4;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer2 {
  width: 66px;
  height: 90px;
  background-image: url("../../assets/images/Composers/composer2.jpg");
  left: 5.9%;
  top: 67px;
  z-index: 2;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer3 {
  width: 124px;
  height: 151px;
  background-image: url("../../assets/images/Composers/composer3.jpg");
  left: 6.8%;
  top: 301px;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer4 {
  width: 134px;
  height: 176px;
  background-image: url("../../assets/images/Composers/composer4.jpg");
  left: 14.23%;
  top: 98px;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer5 {
  width: 163px;
  height: 182px;
  background-image: url("../../assets/images/Composers/composer5.jpg");
  left: 22.74%;
  top: 270px;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer6 {
  width: 126px;
  height: 175px;
  background-image: url("../../assets/images/Composers/composer6.jpg");
  left: 28.26%;
  top: 43px;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer7 {
  width: 115px;
  height: 129px;
  background-image: url("../../assets/images/Composers/composer7.jpg");
  left: 34.47%;
  top: 227px;
  z-index: 2;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer8 {
  width: 66px;
  height: 92px;
  background-image: url("../../assets/images/Composers/composer8.jpg");
  left: 37.91%;
  top: 110px;
  filter: blur(2px);
  opacity: 0.5;
  z-index: 2;
  transform-origin: bottom left;
}
.wrapper .topWrapper .absoluteWrapper .image.composer9 {
  width: 315px;
  height: 428px;
  background-image: url("../../assets/images/Composers/composer9.jpg");
  top: 33px;
  left: 49.45%;
  z-index: 1;
}
.wrapper .topWrapper .absoluteWrapper .image.composer10 {
  width: 95px;
  height: 122px;
  background-image: url("../../assets/images/Composers/composer10.jpg");
  left: 63.71%;
  top: 130px;
  z-index: 2;
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer11 {
  width: 91px;
  height: 115px;
  background-image: url("../../assets/images/Composers/composer11.jpg");
  left: 67.53%;
  top: 283px;
  opacity: 0.4;
  filter: blur(2px);
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer12 {
  width: 131px;
  height: 185px;
  background-image: url("../../assets/images/Composers/composer12.jpg");
  left: 75.93%;
  top: 42px;
  z-index: 2;
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer13 {
  width: 161px;
  height: 229px;
  background-image: url("../../assets/images/Composers/composer13.jpg");
  left: 78.78%;
  top: 223px;
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer14 {
  width: 92px;
  height: 130px;
  background-image: url("../../assets/images/Composers/composer14.jpg");
  left: 86.38%;
  top: 35px;
  opacity: 0.5;
  filter: blur(2px);
  z-index: 2;
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer15 {
  width: 136px;
  height: 192px;
  background-image: url("../../assets/images/Composers/composer15.jpg");
  left: 90.48%;
  top: 138px;
  transform-origin: bottom right;
}
.wrapper .topWrapper .absoluteWrapper .image.composer16 {
  width: 79px;
  height: 101px;
  background-image: url("../../assets/images/Composers/composer16.jpg");
  left: 93.02%;
  top: 364px;
  opacity: 0.5;
  filter: blur(2px);
  transform-origin: bottom right;
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer1, .wrapper .topWrapper.active .absoluteWrapper .image.composer1 {
  transform: translateX(-50%) scale(1.065);
  filter: unset;
  opacity: 1;
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer2, .wrapper .topWrapper.active .absoluteWrapper .image.composer2 {
  transform: translateX(-50%) scale(1.091) rotate(-6.654deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer3, .wrapper .topWrapper.active .absoluteWrapper .image.composer3 {
  transform: translateX(-50%) scale(1.146) rotate(-5.576deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer4, .wrapper .topWrapper.active .absoluteWrapper .image.composer4 {
  transform: translateX(-50%) scale(1.15) rotate(-5.107deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer5, .wrapper .topWrapper.active .absoluteWrapper .image.composer5 {
  transform: translateX(-50%) scale(1.099) rotate(-6.175deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer6, .wrapper .topWrapper.active .absoluteWrapper .image.composer6 {
  transform: translateX(-50%) scale(1.128) rotate(-3.491deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer7, .wrapper .topWrapper.active .absoluteWrapper .image.composer7 {
  transform: translateX(-50%) scale(1.131) rotate(-5.368deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer8, .wrapper .topWrapper.active .absoluteWrapper .image.composer8 {
  transform: translateX(-50%) scale(1.122) rotate(-5.896deg);
  filter: unset;
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer9, .wrapper .topWrapper.active .absoluteWrapper .image.composer9 {
  transform: translateX(-50%) scale(1.113);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer10, .wrapper .topWrapper.active .absoluteWrapper .image.composer10 {
  transform: translateX(-50%) scale(1.116) rotate(7.604deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer11, .wrapper .topWrapper.active .absoluteWrapper .image.composer11 {
  transform: translateX(-50%) scale(1.132) rotate(8.236deg);
  filter: unset;
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer12, .wrapper .topWrapper.active .absoluteWrapper .image.composer12 {
  transform: translateX(-50%) scale(1.1) rotate(7.586deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer13, .wrapper .topWrapper.active .absoluteWrapper .image.composer13 {
  transform: translateX(-50%) scale(1.0689) rotate(5.702deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer14, .wrapper .topWrapper.active .absoluteWrapper .image.composer14 {
  transform: translateX(-50%) scale(1.12) rotate(5.278deg);
  filter: unset;
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer15, .wrapper .topWrapper.active .absoluteWrapper .image.composer15 {
  transform: translateX(-50%) scale(0.95) rotate(11.786deg);
}
.wrapper .topWrapper:hover .absoluteWrapper .image.composer16, .wrapper .topWrapper.active .absoluteWrapper .image.composer16 {
  transform: translateX(-50%) scale(1.24) rotate(7.82deg);
  filter: unset;
}
.wrapper .container {
  display: flex;
  flex-flow: column;
  max-width: 900px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%;
}
.wrapper .container .topTitleWrapper {
  display: flex;
  align-items: center;
  margin: 46px 0;
}
.wrapper .container .topTitleWrapper svg {
  width: 30px;
  height: 58px;
  flex-shrink: 0;
  margin-right: 14px;
}
.wrapper .container .topTitleWrapper .topTitle {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  margin: 0;
}
.wrapper .container .content {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 150px;
}
.wrapper .container .content p {
  color: #1D1D1B;
  text-align: justify;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.62;
}
.wrapper .container .content img {
  max-width: 100%;
  height: auto;
}
.wrapper .container .content div:has(iframe) {
  display: flex;
  align-items: center;
  flex-flow: column;
}
.wrapper .container .content iframe {
  aspect-ratio: 16/9;
  height: auto;
  max-width: 566px;
  width: 100%;
  border: unset;
  margin: 0 auto;
}
.wrapper .container .content :global #pmpFooterContent {
  display: flex;
  margin-top: 180px;
  align-self: center;
}
.wrapper .container .content :global #pmpFooterContent:before {
  content: "";
  width: 96px;
  height: 99px;
  background-image: url("../../assets/images/PL/logoNifcPL.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 30px;
  flex-shrink: 0;
}
.wrapper .container .content :global #pmpFooterContent p {
  margin: 0;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer > p {
  margin-bottom: 20px;
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.62;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer :global #text p a {
  color: #1D1D1B;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer :global #text p a span {
  text-decoration: unset !important;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer :global #text p:last-child {
  display: flex;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer :global #text p:last-child a {
  margin-top: 30px;
  padding: 11px 35px;
  border-radius: 50px;
  border: 1px solid #BE1212;
  color: #BE1212 !important;
  text-decoration: unset !important;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
}
.wrapper .container .content :global #pmpFooterContent :global #contentContainer :global #text p:last-child a:hover {
  background-color: #BE1212;
  color: #ffffff !important;
}
.wrapper .container .content.contentEN :global #pmpFooterContent:before {
  content: "";
  width: 96px;
  height: 99px;
  background-image: url("../../assets/images/EN/nifcLogoEN.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 30px;
  flex-shrink: 0;
}

@media (max-width: 1000px) {
  .wrapper .topWrapper {
    height: 389px;
  }
  .wrapper .topWrapper .absoluteWrapper {
    transform: scale(0.8);
    top: -40px;
  }
  .wrapper .container {
    padding: 0 30px;
    max-width: 880px;
  }
}
@media (max-width: 700px) {
  .wrapper .topWrapper {
    height: 292px;
  }
  .wrapper .topWrapper .absoluteWrapper {
    transform: scale(0.6);
    top: -60px;
  }
}
@media (max-width: 500px) {
  .wrapper .topWrapper {
    height: 243px;
  }
  .wrapper .topWrapper .absoluteWrapper {
    transform: scale(0.5);
    top: -60px;
  }
  .wrapper .container {
    padding: 0 20px;
  }
  .wrapper .container .topTitleWrapper .topTitle {
    font-size: 25px;
  }
  .wrapper .container .content {
    margin-bottom: 50px;
  }
  .wrapper .container .content p {
    font-weight: 400;
    line-height: 1.2;
    font-size: 14px;
  }
  .wrapper .container .content :global #pmpFooterContent {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-top: 50px;
  }
  .wrapper .container .content :global #pmpFooterContent:before {
    margin-right: unset;
    margin-bottom: 30px;
  }
  .wrapper .container .content :global #pmpFooterContent :global #contentContainer p {
    font-size: 14px;
    line-height: 1.8;
  }
}/*# sourceMappingURL=aboutPage.module.css.map */