.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #ECECEC;
  border-bottom: #BE1212 1px solid;

  .button {
    padding: 20px 30px;

    .buttonContent {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .text {
      color: #BE1212;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      opacity: 0;

      &.textFade {
        opacity: 1;
        transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 13px;
        transition: 0.3s ease-in-out;
      }

      &.rotate {

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .content {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 25px;
  }
}

@media (max-width: 500px) {
  .wrapper {

    .button {
      padding: 20px;
    }

    .content {
      padding: 0 20px;
    }
  }
}