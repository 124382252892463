.reactSlidedown {
  height: 0;
  transition-property: none;
  transition-duration: .35s;
  transition-timing-function: ease-in-out;
  width: inherit;
}

.transitioning {
  overflow-y: hidden;
}

.closed {
  display: none;
}