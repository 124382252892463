.link {
  display: flex;
  flex-flow: column;
  flex-direction: column;
  position: relative;
}
.link .imageBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-top: 62%;
  margin-bottom: 20px;
}
.link .imageBackground .image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 2px solid #BE1212;
  filter: contrast(0.9);
  background-size: cover;
  background-position: center;
  transition: 0.25s ease-in-out;
}
.link .imageBackground .imagePlaceHolder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BE1212;
  text-transform: uppercase;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 2px solid #BE1212;
  filter: contrast(0.9);
  background-size: cover;
  background-position: center;
  transition: 0.25s ease-in-out;
}
.link .imageBackground .imageText {
  text-transform: uppercase;
  color: #BE1212;
  margin: 160px 0;
}
.link .bold {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin: 15px 0;
  transition: 0.25s ease-in-out;
}
.link .last {
  color: #646464;
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin: 12px 0;
}
.link:hover .imageBackground .image {
  transform: scale(1.1);
}
.link:hover .itemWrapper .bold {
  color: #BE1212;
}

.noComposerLink {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  transition: 0.25s ease-in-out;
  border-radius: 6px;
  min-height: 74px;
}
.noComposerLink .itemWrapper {
  display: flex;
  flex-flow: column;
  padding: 6px 15px 6px 0;
  justify-content: center;
}
.noComposerLink .itemWrapper .label {
  color: #9F9F9F;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  display: none;
}
.noComposerLink .itemWrapper .itemText {
  color: #1D1D1B;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  transition: 0.25s ease-in-out;
}
.noComposerLink .itemWrapper .itemText.bold {
  font-weight: 700;
}
.noComposerLink .itemWrapper.first {
  padding-left: 6px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}
.noComposerLink .itemWrapper.first .imageBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 85px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 15px;
}
.noComposerLink .itemWrapper.first .imageBackground .image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 52px;
  height: 71px;
}
.noComposerLink .itemWrapper.last {
  align-items: flex-end;
}
.noComposerLink .itemWrapper.last .itemText {
  text-align: right;
}
.noComposerLink .itemWrapper:first-child {
  padding-left: 6px;
}
.noComposerLink.dark {
  background-color: #EFEFEF;
}
.noComposerLink:hover {
  background-color: #BE1212;
}
.noComposerLink:hover .itemWrapper .itemText {
  color: #ffffff;
}

@media (max-width: 1100px) {
  .noComposerLink {
    display: flex;
    grid-template-columns: unset;
    flex-wrap: wrap;
    padding: 15px 13px 20px;
  }
  .noComposerLink .itemWrapper {
    width: 100%;
    padding: unset;
    justify-content: unset;
  }
  .noComposerLink .itemWrapper .label {
    display: block;
  }
  .noComposerLink .itemWrapper:first-child {
    padding: unset;
  }
  .noComposerLink .itemWrapper.bold .itemText {
    font-size: 16px;
    margin: 10px 0;
  }
  .noComposerLink .itemWrapper.type {
    width: calc((100% - 40px) / 2);
    margin-right: auto;
  }
  .noComposerLink .itemWrapper.last {
    width: calc((100% - 40px) / 2);
    margin: 5px 0;
    align-items: flex-start;
  }
  .noComposerLink .itemWrapper.last .itemText {
    text-align: left;
  }
}
@media (max-width: 1000px) {
  .noComposerLink {
    grid-template-columns: 230px 1fr 180px 1fr;
  }
  .noComposerLink {
    grid-template-columns: 1fr 100px 170px;
  }
}
@media (max-width: 800px) {
  .noComposerLink {
    grid-template-columns: 150px 1fr 150px 1fr;
  }
  .noComposerLink .itemWrapper:first-child {
    padding-left: 15px;
  }
  .noComposerLink .itemWrapper:first-child .imageBackground {
    display: none;
  }
}
@media (max-width: 760px) {
  .noComposerLink {
    display: flex;
    grid-template-columns: unset;
    flex-wrap: wrap;
    padding: 15px 13px 20px;
  }
  .noComposerLink .itemWrapper {
    width: 100%;
    padding: unset;
    justify-content: unset;
  }
  .noComposerLink .itemWrapper .label {
    display: block;
  }
  .noComposerLink .itemWrapper:first-child {
    padding: unset;
  }
  .noComposerLink .itemWrapper.bold .itemText {
    font-size: 16px;
    margin: 10px 0;
  }
  .noComposerLink .itemWrapper.type {
    width: calc((100% - 40px) / 2);
    margin-right: auto;
  }
  .noComposerLink .itemWrapper.last {
    width: calc((100% - 40px) / 2);
    align-items: flex-start;
  }
  .noComposerLink .itemWrapper.last .itemText {
    text-align: left;
  }
}
@media (max-width: 500px) {
  .noComposerLink .itemWrapper {
    display: inline-block;
  }
  .noComposerLink .itemWrapper .label {
    margin-bottom: unset;
    margin-right: 5px;
    display: inline;
  }
  .noComposerLink .itemWrapper.type {
    width: 100%;
    flex-flow: row;
    margin-bottom: 5px;
  }
  .noComposerLink .itemWrapper.last {
    width: 100%;
    flex-flow: row;
  }
}/*# sourceMappingURL=articleItem.module.css.map */