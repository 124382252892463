.wrapper {
  display: flex;

  .shareButton {
    border: unset;
    cursor: pointer;
    background-color: unset;
    padding: unset;
    display: flex;

    svg {
      width: 30px;
      height: 30px;
    }

    rect {
      transition: 0.3s all;
    }

    &:hover {
      rect {
        fill: #000000;
      }
    }
  }
}