.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;

  .container {
    display: flex;
    flex-flow: column;
    max-width: 1320px;
    padding: 56px 40px 0;
    margin: 0 auto;
    width: 100%;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      .inputWrapper {
        flex: 0 0 310px;
      }

      .searchWrapper {
        display: flex;
        flex-flow: column;
        max-width: 530px;
        width: 100%;
        margin-right: 40px;

        .searchTitle {
          color: #1D1D1B;
          font-family: "Plus Jakarta Sans", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          text-transform: uppercase;
          margin: 0 0 18px;
        }
      }

      .tagsWrapper {
        display: flex;
        flex-flow: column;

        .tagsTitle {
          color: #1D1D1B;
          font-family: "Plus Jakarta Sans", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          text-transform: uppercase;
          margin: 0 0 18px;
        }

        .content {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 640px;
          margin: auto 0;

          .tagButton {
            border-radius: 5px;
            border: 1px solid #ECECEC;
            background: #FFF;
            text-transform: uppercase;
            margin: 3px 6px 3px 0;
            color: #1D1D1B;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            padding: 4px 6px;
            transition: 0.25s ease-in-out;

            &.active {
              background-color: #be1212;
              color: #FFFFFF;
            }

            &:hover {
              background-color: #720b0b;
              color: #FFFFFF;
            }

            &:last-child {
              margin-right: unset;
            }
          }
        }
      }
    }

    .listWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      padding: 60px 0 0;
      margin-bottom: 72px;
      grid-auto-flow: dense;
      transition: 0.3s ease-in-out;

      &.blur {
        filter: blur(3px);
      }
    }

    .showMoreButton {
      border-radius: 50px;
      border: 1px solid #BE1212;
      background: #FFF;
      transition: 0.3s ease-in-out;
      margin: 0 auto 88px;
      padding: 10px 35px;
      color: #BE1212;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.57;

      &:hover {
        background: #BE1212;
        color: #FFF;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {

    .container {
      padding: 0 30px 85px;

      .top {
        margin-top: 56px;
      }
    }
  }
}

@media (max-width: 800px) {

  .wrapper {

    .container {

      .listWrapper {
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        margin-bottom: 50px;

        .itemWrapper {

          &:nth-child(6n + 1) {

            >div {

              &:nth-child(2) {
                padding-top: calc((100% - 20px));
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {

  .wrapper {

    .container {
      padding: 0 0 0;

      .listWrapper {
        column-gap: 0;
        padding-left: 30px;
        padding-right: 30px;
      }

      .top {
        flex-direction: column;
        margin-top: 0;

        .searchWrapper {
          margin-bottom: 18px;
          margin-right: 0;
          max-width: unset;
        }

        .inputWrapper {
          flex: unset;
          width: 100%;
        }
      }
    }
  }

  .wrapper {

    .container {

      .listWrapper {
        padding-top: 30px;
        grid-template-columns: 1fr;

        a {
          grid-column: 1/2;
        }
      }
    }
  }

}

@media (max-width: 500px) {

  .wrapper {

    .container {

      .listWrapper {
        padding: 30px 20px 0 20px;
      }
    }
  }
}