.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100000000000;
  background-color: white;
  flex-flow: column;
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
}

.loaderInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  margin-left: calc(50vw - 60px);
}
.loaderInner .circle {
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
}
.loaderInner .one svg {
  height: 120px;
  width: 120px;
  animation: rotate linear infinite;
  animation-duration: 7s;
}
.loaderInner .two svg {
  height: 120px;
  width: 120px;
  animation: rotate2 linear infinite;
  animation-duration: 2s;
}
.loaderInner.loaderInnerRotate .one svg {
  animation: unset;
}
.loaderInner.loaderInnerRotate .two svg {
  animation: unset;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.loaderHide {
  opacity: 0;
}/*# sourceMappingURL=loaderPage.module.css.map */