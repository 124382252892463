html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
  background-color: #F5F5F5;
}

* {
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 1.2;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  outline: unset;
}

a {
  text-decoration: unset;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: unset;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBoldItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Italic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-BoldItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLightItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBoldItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-MediumItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Light.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-LightItalic.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff2") format("woff2"), url("../../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.bodyOverflow {
  overflow: hidden;
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
  width: 100%;
}
.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}
.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.rcs-custom-scroll .rcs-inner-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}
.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: #BE1212;
  border-radius: 3px;
}

@media print {
  body {
    background-color: transparent;
    -webkit-print-color-adjust: exact;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}/*# sourceMappingURL=styles.css.map */